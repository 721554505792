export function parseFileName(fileName = '') {
  const reg = /(.*)\.(.+)/;
  const rst = reg.exec(fileName);
  let name = fileName;
  let ext = '';

  if (rst) {
    [, name, ext] = rst;
  }

  return {
    name,
    ext: ext.toLowerCase(),
  };
}
