import { ossFileUpload } from '@lego/utils';

type UploadConfig = Parameters<typeof ossFileUpload>[0];

const DEFAULT_UPLOAD_CONFIG = {
  env: process.env.GATSBY_Env,
  bucket: process.env.GATSBY_Bucket,
  region: process.env.GATSBY_Region,
  network_type: process.env.GATSBY_NetworkType,
  directory: process.env.GATSBY_Env !== 'production' ? 'official-site/' : '',
};

export function upload(config: Partial<UploadConfig>): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    ossFileUpload({
      ...DEFAULT_UPLOAD_CONFIG,
      ...config,
      onSuccess: (res) => {
        console.log('upload success');
        resolve(res.data.url);
      },
      onError: (err: any) => reject(err),
    } as UploadConfig);
  });
}
