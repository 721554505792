import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from '@reach/router';
import { Divider, Input, message, Tabs, Upload, UploadProps, Form, Button, Empty } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { navigate } from 'gatsby';
import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import API from '@/models';
import { Job } from '@/types/job';
import { EmplTypeList } from '@/constants/job';
import { upload } from '@/utils/upload';
import { useSiteConfig } from '@/hooks/SiteConfig';
import HtmlRender from '@/components/HtmlRender/index';
import { Tab } from '@/constants/apply-job';
import { Department } from '../types/department';
import { Location } from '../types/location';
import { parseFileName } from '../utils/file';

const { TabPane } = Tabs;
const { Dragger } = Upload;

const DEFAULT_FORM = {
  job_id: '',
  name: '',
  email: '',
  resume_file: '',
  apply_reason: '',
  file_ext: '',
  phone: '',
}

const ACCEPT_FILE_TYPE = ['.pdf', '.doc', '.docx'];

const ApplyJob = props => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { career_url, apply_job_url} = useSiteConfig();

  const [currentJob, setCurrentJob] = useState<Job | null>(null);
  const [curForm, setCurForm] = useState(DEFAULT_FORM);
  const [curJobId, setCurJobId] = useState(searchParams.get('id'));
  const [currentTab, setCurrentTab] = useState(searchParams.get('tab') || Tab.Overview);
  const [locations, setLocations] = useState<Location[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [renderKey, setRenderKey] = useState(new Date().getTime());
  const [loading, setLoading] = useState(true);

  const uploadProps: UploadProps = {
    name: 'file',
    accept: ACCEPT_FILE_TYPE.join(','),
    beforeUpload: (file) => {
      const isLessThan5M = file.size / 1024 / 1024 < 5;
      if (!isLessThan5M) {
        message.error('The file must be smaller than 5MB');
      }
      return isLessThan5M ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if(info.fileList.length > 1){
        info.fileList.shift();
      }
    },
    onRemove: () => {
      setCurForm({...curForm, resume_file: ''});
    },
    customRequest: handleUpload,
  };

  const submitBtnDisabled = useMemo(() => !curForm.name || !curForm.email || !curForm.resume_file, [curForm]);

  useEffect(() => {
    const id = searchParams.get('id');
    if(!id) return;
    setCurJobId(id);
  }, [search]);

  useEffect(() => {
    init();
  }, [curJobId]);

  useEffect(() => {
    navigate(`${apply_job_url}?id=${curJobId}&tab=${currentTab}`);
  }, [currentTab]);

  async function init() {
    setLoading(true);
    await Promise.all([getJobDetail(), getDepartmentList()]);
    setLoading(false);
  }

  function handleTabChange(key: Tab) {
    setCurrentTab(key);
  }

  async function getDepartmentList(){
    const { list: locationList } = await API.getLocationList();
    setLocations(locationList);

    const { list: deptList } = await API.getDepartmentList();
    setDepartments(deptList);
  }

  async function getJobDetail() {
    if(!curJobId){
      return;
    }
    try {
      const job = await  API.getJobDetail(curJobId);
      setCurrentJob(job);
    }catch(err) {
      console.log(err);
    }
  }

  async function handleUpload(e: any) {
      const  { file, onSuccess }   = e;

      try {
        const data = await upload({ file });
        const { ext } = parseFileName(file.name);
        setCurForm({...curForm, resume_file: data, file_ext: ext});
        message.success('upload success');
        onSuccess("ok", file);
      } catch (err){
        console.log(err);
      }
   }

   async function handleApply() {
    try {
      await API.applyJob({...curForm, job_id: curJobId});
      setCurForm({...DEFAULT_FORM, job_id: curJobId});
      setRenderKey(new Date().getTime());
      message.success('Apply successfully');
    } catch (err){
      console.log(err);
    }
   }
  return (
    <Layout bodyClass="page-apply-job">
      <SEO title="ApplyJob" />
      <div className="d-flex flex-grow-1 overflow-hidden">
        { !loading && currentJob && <div className="page-apply-job__container">
          <div className="page-apply-job__header">
            <div className="page-apply-job__back-btn">
              <i className="bi bi-arrow-left-circle fs-4" onClick={() => navigate(career_url)}></i>
            </div>
            <div className="page-apply-job__job-name">{currentJob?.name}</div>
          </div>

          <div className="page-apply-job__main">
            <div className="page-apply-job__main-left">
              <div className="d-flex flex-column gap-1">
                <div className="fw-bold">
                  <i className="bi bi-geo-alt-fill"></i> Location
                </div>
                <div>
                  {currentJob?.location_ids.map(id => locations.find(item => item.id === id)?.location).join(' • ')}
                </div>
                <Divider />
              </div>

              <div className="d-flex flex-column gap-1">
                <div className="fw-bold">
                  <i className="bi bi-buildings-fill"></i> Department
                </div>
                <div>
                  {departments.find(item => item.id === currentJob?.department_id)?.name}
                </div>
                <Divider />
              </div>

              <div className="d-flex flex-column gap-1">
                <div className="fw-bold">
                  <i className="bi bi-bookmark-fill"></i> Type
                </div>
                <div>
                  {currentJob?.employment_types.map(type => (EmplTypeList.find(item => item.value === type)!).label).join(' • ')}
                </div>
                <Divider />
              </div>
            </div>

            <div className="page-apply-job__main-right">
              <Tabs defaultActiveKey={currentTab} size="large" onChange={handleTabChange}>
                <TabPane tab={Tab.Overview} key={Tab.Overview}>
                  <HtmlRender html={currentJob?.description}></HtmlRender>
                </TabPane>
                <TabPane tab={Tab.Apply} key={Tab.Apply}>
                  <Form layout="vertical" key={renderKey}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[
                                 {
                                   required: true,
                                   message: 'Name is required',
                                 },
                               ]}>
                      <Input value={curForm.name} onChange={(e) => {setCurForm({...curForm, name: e.target.value})}} />
                    </Form.Item>

                    <Form.Item label="Email"
                               name="email"
                               rules={[
                                 {
                                   required: true,
                                   message: 'Email is required',
                                 },
                                 {
                                   type: 'email',
                                   message: 'Not a valid email',
                                 }]} >
                      <Input value={curForm.email} onChange={(e) =>  {setCurForm({...curForm, email: e.target.value})}} />
                    </Form.Item>

                    <Form.Item label="Resume"
                               name="file"
                               rules={[
                                 {
                                   required: true,
                                   message: 'Resume is required',
                                 }]}>
                      <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag your resume to this area to upload</p>
                        <p>accept file type: {ACCEPT_FILE_TYPE.join(', ')}<br /> file size limit: less than 5MB</p>
                      </Dragger>
                    </Form.Item>

                    <Form.Item label="Phone Number"
                               name="phone"
                               rules={[
                                  {
                                    pattern:  /^[\d+() -]+$/,
                                    message: 'Not a valid phone number',
                                  },
                                ]}>
                      <Input
                        value={curForm.phone}
                        maxLength={50}
                        onChange={e => {
                          setCurForm({ ...curForm, phone: e.target.value});
                        }}/>
                    </Form.Item>

                    <Form.Item label="Additional Information">
                      <TextArea rows={4} value={curForm.apply_reason} onChange={e => {
                        setCurForm({ ...curForm, apply_reason: e.target.value });
                      }} />
                    </Form.Item>
                  </Form>

                  <Form.Item>
                    <div className="d-flex justify-content-center mt-5">
                   <Button onClick={handleApply} disabled={submitBtnDisabled} htmlType="submit">Submit</Button>
                      </div>
                  </Form.Item>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div> }
        { !loading && !currentJob && <Empty style={{height: '100%', width: '100%'}} className="d-flex flex-column justify-content-center align-items-center" /> }
      </div>
    </Layout>
  );
};

export default ApplyJob;
